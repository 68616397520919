import React from 'react';
import NavigationIcons from './NavigationIcons';
import Bio from './Bio';
import './Header.css';

const Header = () => (
  <header className="header">
    <Bio />
    <NavigationIcons />
  </header>
);

export default Header;
