import React from "react";
import { VerticalTimeline } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./ProfChrono.css";
import JobExperience from "./JobExperience";

// Utility function to calculate duration
export const calculateDuration = (startDate, endDate = new Date()) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const totalMonths = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
  const years = Math.floor(totalMonths / 12);
  const months = totalMonths % 12;

  const yearPart = years ? `${years} year${years > 1 ? "s" : ""}` : "";
  const monthPart = months ? `${months} month${months > 1 ? "s" : ""}` : "";
  
  return [yearPart, monthPart].filter(Boolean).join(" ");
};

// Experiences data
const experiences = [
  {
    title: "Data Scientist",
    company: "Datacation · Full-time",
    duration: calculateDuration("2024-09-15"),
    startDateString: "Sep 2024",
    endDateString: "Present",
    location: "Amsterdam Zuid, Netherlands · Hybrid",
    // logo: "./content-icons/datacation.png",
    description: `
    Datacation is a AI and data science consultancy company, currently in the startup phase.
    `,
  },
  {
    title: "Data Scientist",
    company: "Artefact · Full-time",
    duration: calculateDuration("2023-09-15", "2024-09-01"),
    startDateString: "Sep 2023",
    endDateString: "Sep 2024",
    location: "Utrecht, Netherlands · Hybrid",
    logo: "./content-icons/artefact.svg",
    description: `
    Artefact is a consultancy company specialised in AI and digital marketing.
      - Collaborated within a team to analyze marketing performance for a multi-billion-dollar retail client in the US market, leveraging causality analysis and time-series forecasting techniques.
      - Led the end-to-end development and deployment of a Bayesian-based ranking model for a popular e-sports game, including research, planning, implementation, and delivery.
      - Mentored an intern over a 5-week period, providing technical guidance and ensuring timely completion of an internal solution aligned with project goals and deliverables.
    `,
  },
  {
    title: "Software Developer",
    company: "Grant Thornton Netherlands · Part-time",
    duration: calculateDuration("2020-11-01", "2023-09-15"),
    startDateString: "Nov 2020",
    endDateString: "Sep 2023",
    location: "Gouda, South Holland, Netherlands · Hybrid",
    logo: "./content-icons/gt.svg",
    description: `
    Grant Thornton is a global audit, tax, and advisory firm.
      During my tenure at Grant Thornton, I embraced the challenge of balancing part-time availability with the pursuit of excellence. Leveraging my expertise, I actively contributed to various projects, leaving a tangible mark on the company's operations. Here's how I made an impact:
      - Collaborated with non-technical colleagues, translating business challenges into actionable technical solutions.
      - Delivered multiple successful full-stack projects using Outsystems, including the Tax Reporting System.
      - Designed and implemented a customized surveying system, enhancing workflows.
      - Developed systems for financial document parsing and invoice generation automation.
    `,
  },
  {
    title: "Software Developer",
    company: "ScenWise · Internship",
    duration: calculateDuration("2020-05-01", "2020-07-14"),
    startDateString: "May 2020",
    endDateString: "July 2020",
    location: "Netherlands · Remote",
    logo: "./content-icons/scenwise.svg",
    description: `
    ScenWise is a software development company works in the mobility sector.
      Contributed to a traffic data analysis web application back-end using Java, REST API, Spring framework, and database technologies.
    `,
  },
];

// ProfChrono Component
const ProfChrono = () => {
  return (
    <VerticalTimeline layout="1-column" className="vertical-timeline-custom-line">
      {experiences.map((experience, index) => (
        <JobExperience
          key={index}
          experience={experience}
          highlight={index === 0}
        />
      ))}
    </VerticalTimeline>
  );
};

export default ProfChrono;
