import React, { useState } from 'react';
import './ProjectSection.css';
import useWindowSize from "../useWindowSize";

const projects = [
  {
    title: "A System for Model Diagnosis centered around Human Computation",
    description: "This thesis addresses the issue of reliability in machine learning (ML) systems used for computer vision applications, which can fail when faced with slightly different data than their training set. The author proposes a system to help ML practitioners debug their computer vision models before deployment. The system utilizes human computation operations to identify expected model behaviors, compare them to actual model behaviors, and assess model performance. The author claims that their system is the first to allow ML practitioners to define debugging goals, configure debugging sessions, and automatically generate model debugging reports. They conducted a comprehensive evaluation of the system, demonstrating its correctness, informativeness, and cost-effectiveness, even when considering potential human errors.Despite some limitations, the work represents an important step towards assisting practitioners in debugging ML models, encouraging further optimization, and providing open access to their code for broader usage and experimentation.."
  },
  { title: "Sharing human mental model with an AI agent to achieve team effectiveness", description: "The paper discusses the collaboration between humans and digital computers in various tasks, emphasizing the importance of combining artificial intelligence (AI) agents with humans to improve team effectiveness. The focus is on the human-AI agent team and the role of shared mental models in team performance. The paper aims to experimentally analyze how different shared mental models impact human-AI agent collaboration. The hypothesis is that exchanging more information within the shared mental model between humans and AI agents leads to higher team performance. The paper outlines the research questions, subdivides the research problem, and describes the experimental design. It also presents results and statistical analyses, touches on ethical considerations, and provides a discussion of the experiment's outcomes and future research recommendations before concluding." },
];

const ScientificResearch = () => {
  const { width } = useWindowSize();
  const [selectedProject, setSelectedProject] = useState(null);

  const handleProjectClick = (index) => {
    setSelectedProject(index === selectedProject ? null : index);
  };

  return (
    <div>
      <div className="project-grid">
        {projects.map((project, index) => (
          <div
            key={index}
            className={`project-item ${selectedProject === index ? 'active' : ''}`}
            onClick={() => handleProjectClick(index)}
          >
            <img src="./content-icons/research.svg" alt={project.title} />
            <h3>{project.title}</h3>
            {width < 800 && selectedProject === index &&
              <div className="project-details">
                <p>{projects[index].description}</p>
              </div>
            }
          </div>
        ))}
      </div>
      {selectedProject !== null && width >= 800 && (
        <div className="project-details">
          <h3>{projects[selectedProject].title}</h3>
          <p>{projects[selectedProject].description}</p>
        </div>
      )}
  </div>
  );
};

export default ScientificResearch;
