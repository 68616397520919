import React, { useState } from 'react';
import './ProjectSection.css';
import useWindowSize from "../useWindowSize";

const projects = [
  {
    title: "Enhancing Clickbait Detection with Modern NLP Techniques",
    description: "We developed a clickbait detection model using modern NLP techniques such as BERT and RoBERTa to improve upon the results from the Clickbait Challenge 2017. We conducted an error analysis on the data to further investigate the shortcomings of the model's performance on the data. The model focused on textual information and outperformed state-of-the-art methods at the time, showing potential for further enhancements and applications in social media content analysis."
  },
  { title: "Information Retrieval Model Evaluation and Improvement", description: "In this project, we implemented and evaluated two information retrieval models: the BM25 probabilistic model and a LambdaMART learning-to-rank model. We identified weaknesses in the BM25 model through error analysis and proposed improvements, including incorporating the RM3 association-based language model, spelling correction, and stop word removal. The most promising improvement was BM25+RM3, while stop word removal and autocorrect did not show significant improvements. Future work could explore tuning the RM3 model, dense retrieval methods, and training the LambdaMART model on a larger dataset." },
  { title: "Conversational Furhat bot", description: "We developed a Rule based conversational bot. The bot was designed to interact with the user and respond with reasonable answers depending on the input." },
  { title: "Multi-label Classification using Pattern Exploiting Training", description: "Explored the use of Pattern Exploiting Training (PET) for multi-label classification tasks by applying it to the Moral Foundations Twitter Corpus (MFTC) dataset. We experimented with various patterns and verbalizers, and adapted the model for multi-mask classification." },
  { title: "Understanding IMDb Scores through Visualization and Data Analysis", description: "In this project, we address the challenge of quantifying subjective experiences by exploring the factors that influence IMDb scores. We design and implement a visualization tool for exploratory data analysis (EDA) using a dataset consisting of the raw scripts from the popular television franchise Star Trek. Our approach involves extracting relevant features, such as sentiment analysis and term frequency-inverse document frequency (TF-IDF), from the scripts and creating an interactive dashboard with various visualization graphs, such as scatterplot matrices and histograms. Although our analysis focuses on Star Trek scripts, our tool is designed with generalizability in mind, allowing for easy adaptation to any media with textual representation in script format. This project could be valuable to professionals like producers or IMDb employees seeking insights into what features of a script influence IMDb scores." },
  { title: "Bubble Spinner", description: "This project was to create a bubble spinner game from scratch. It was coded in Java and created with the LibGDX library for the frontend and Spring Framework for the back end. The project was built to maintain high code quality with high maintainability and extensibility using software engineering methods." },
  { title: "Green Streak", description: "The application aimed to monitor the CO2 consumption for the user as it takes the input from the user stores it and create statistical models on how much CO2 was reduced by doing certain activities. This project was written in java and consisted of GUI to display and receive the user input and backend to store and process the data. The application had a friend's system and leaderboards to motivate the users to preserve the environment and to keep their co2 consumption low." }
];

const ProjectSection = () => {
  const { width } = useWindowSize();
  const [selectedProject, setSelectedProject] = useState(null);

  const handleProjectClick = (index) => {
    setSelectedProject(index === selectedProject ? null : index);
  };

  return (
    <section id="projects" className="project-section">
      <div className="project-grid">
        {projects.map((project, index) => (
          <div
            key={index}
            className={`project-item ${selectedProject === index ? 'active' : ''}`}
            onClick={() => handleProjectClick(index)}
          >
            <img src="./content-icons/project.svg" alt={project.title} />
            <h3>{project.title}</h3>
            {width < 800 && selectedProject === index &&
              <div className="project-details">
                <p>{projects[index].description}</p>
              </div>
            }
          </div>
        ))}
      </div>
      {selectedProject !== null && width >= 800 && (
        <div className="project-details">
          <h3>{projects[selectedProject].title}</h3>
          <p>{projects[selectedProject].description}</p>
        </div>
      )}
    </section>
  );
};

export default ProjectSection;
