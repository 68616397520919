import React from 'react';
import './Skills.css';

// const experiences = [
//   { level: "High", color: "#4CAF50", percentage: 100 },
//   { level: "Medium", color: "#FF9800", percentage: 70 },
//   { level: "Low", color: "#F44336", percentage: 40 }
// ];

const skills = [
  {
    group: 'Soft Skills',
    items: [
      { name: 'Leadership', experience: 'High' }, { name: 'Teamwork', experience: 'High' },
      { name: 'Problem Solving', experience: 'High' }, { name: 'Communication', experience: 'High' },
      { name: 'Time Management', experience: 'High' }
    ]
  },
  {
    group: 'Programming Languages',
    items: [
      { name: 'Python', experience: 'High' }, { name: 'Java', experience: 'High' },
      { name: 'JavaScript', experience: 'High' }, { name: 'SQL', experience: 'High' },
      { name: 'C++', experience: 'Medium' }, { name: 'R', experience: 'Medium' }
    ]
  },
  
  {
    group: 'Machine Learning',
    items: [
      { name: 'Time Series Analysis', experience: 'High' }, { name: 'Natural Language Processing', experience: 'High' },
      { name: 'Computer Vision', experience: 'High' }, { name: 'Data Analysis', experience: 'High' },
      { name: 'Bayesian Statistics', experience: 'High' }, { name: 'Deep Learning', experience: 'High' },
      { name: 'Reinforcement Learning', experience: 'Medium' }, { name: 'Causal Inference', experience: 'Medium' },
      { name: 'ML Ops', experience: 'Medium' }, { name: 'Data Engineering', experience: 'Medium' },
    ]
  },
  {
    group: 'Software Development',
    items: [
      { name: 'Git', experience: 'High' }, { name: 'Docker', experience: 'High' },
      { name: 'Software Design', experience: 'High' },
      { name: 'Software Testing', experience: 'High' }, { name: 'Full-Stack Development', experience: 'High' }
    ]
  },
  
  {
    group: 'Technologies',
    items: [
      { name: 'OutSystems', experience: 'Medium' },
      { name: "Databricks", experience: "Medium" },
    ]
  },
  {
    group: 'Web Development',
    items: [
      { name: 'HTML', experience: 'High' }, { name: 'CSS', experience: 'High' },
      { name: 'React', experience: 'High' }, { name: 'Node.js', experience: 'Medium' },
      { name: 'Flask', experience: 'Medium' }, { name: 'FastAPI', experience: 'Medium' },
      { name: 'PostgreSQL', experience: 'High' },{ name: 'MongoDB', experience: 'Medium' },
    ]
  },
];

const Skills = () => (
<div>
    <div className="skills-container">
    {skills.map((skill, index) => (
      <div key={index} className="skill-card">
        <h3>{skill.group}</h3>
        <ul>
          {skill.items.map((item, itemIndex) => {
            // const experience = experiences.find(exp => exp.level === item.experience);
            return (
              <li key={itemIndex}>
                <div className="skill-name">{item.name}</div>
                {/* <div className="skill-bar-container">
                  <div className="skill-bar" style={{ width: `${experience.percentage}%`, backgroundColor: experience.color }}></div>
                </div> */}
              </li>
            );
          })}
        </ul>
      </div>
    ))}
    </div>
    {/* <div style={{display: 'flex', justifyContent: 'left', color: 'white', width: '100%', height: '100%', padding: '1rem'}}>
        Skill experience based on my personal assessment on three axis years of experience, use of the skill in projects, and knowledge of the skill.
    </div> */}
</div>
);

export default Skills;
