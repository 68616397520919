import React, { useEffect } from 'react';
import './BackgroundIcons.css';
import useWindowSize from './useWindowSize';
const icons = [
  { src: "./background-icons/magnifier.svg", style: { right: '1vw', top: '20vh'} },
  { src: "./background-icons/software.svg", style: { left: '1vw', top: '10vh'} },
  { src: "./background-icons/react-logo.svg", style: { right: '1vw', top: '1vh'} },
  { src: "./background-icons/uncharted.svg", style: { left: '1vw', top: '20vh'} },
  { src: "./background-icons/git.svg", style: { left: '-2vw', top: '-4vh'} },
  { src: "./background-icons/python.svg", style: { right: '20vw', top: '5vh'} },
  { src: "./background-icons/server.svg", style: { left: '8vw', top: '5vh'} },
  { src: "./background-icons/desktop.svg", style: { right: '4vw', top: '18vh'} },
  { src: "./background-icons/java.svg", style: { right: '5vw', top: '30vh'} },
  { src: "./background-icons/ring.svg", style: { left: '80vw', top: '-2vh'} },
  { src: "./background-icons/fastapi.svg", style: { left: '30vw', top: '5vh'} },
  { src: "./background-icons/software-developer.svg", style: { right: '15.8vw', top: '15.8vh', height: '50vh' } },
  { src: "./background-icons/tensorflow.svg", style: { right: '27vw', top: '1vh', height: '20vh' } },
  { src: "./background-icons/chart.svg", style: { left: '50vw', top: '9vh'} },
  { src: "./background-icons/database.svg", style: { right: '10vw', top: '10vh'} }
];

const BackgroundIcons = () => {
  const {width} = useWindowSize();
  useEffect(() => {
    const imgs = document.querySelectorAll('.background-icons img');

    imgs.forEach(img => {
      if (!img.src.includes('software-developer')) {
        const randomX = `${Math.random() * 20 - 10}px`;
        const randomY = `${Math.random() * 100 + 10}px`;
        const randomDuration = `${Math.random() * 10 + 2}s`;

        img.style.setProperty('--random-x', randomX);
        img.style.setProperty('--random-y', randomY);
        img.style.setProperty('--random-duration', randomDuration);
      }
    });
  }, []);

  return (
    <div className='background-icons'>
      {icons.map((icon, index) => (
        <img
          key={index}
          src={icon.src}
          alt="background icon"
          style={adjustIconStyle(icon.style, icon.src.includes('software-developer'), width)}
        />
      ))}
    </div>
  );
};

const adjustIconStyle = (style, is_big, width) => {
  const screenWidth = width;
  let adjustedStyle = { ...style };

  if (screenWidth <= 600) {

    if (is_big) {
      adjustedStyle = {
        ...adjustedStyle,
        height: '20vh'
      };
    }else{
      adjustedStyle = {
        ...adjustedStyle,
        height: '7vh'
      };
    }

    // Adjust positioning if necessary
    if (adjustedStyle.right) {
      adjustedStyle.right = `calc(${adjustedStyle.right} * 0.5)`;
    }
    if (adjustedStyle.left) {
      adjustedStyle.left = `calc(${adjustedStyle.left} * 0.5)`;
    }
    if (adjustedStyle.top) {
      adjustedStyle.top = `calc(${adjustedStyle.top} * 0.5)`;
    }
    if (adjustedStyle.bottom) {
      adjustedStyle.bottom = `calc(${adjustedStyle.bottom} * 0.5)`;
    }
  }

  return adjustedStyle;
};

export default BackgroundIcons;
