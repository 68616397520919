import React from "react";
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import useWindowSize from "../useWindowSize";
import './ProfChrono';

const renderDescription = (description) => {
  return description.split('\n').map((line, index) => {
    if (line.startsWith(' - ')) {
      return <li key={index}>{line.substring(3)}</li>;
    }
    return <p key={index}>{line}</p>;
  });
};

const JobExperience = ({ experience, highlight }) => {
  const { width } = useWindowSize();

  const iconStyle = {
    background: highlight ? 'seagreen' : '#1A222B',
    color: '#fff'
  };

  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{ background: '#1A222B', color: '#fff' }}
      contentArrowStyle={{ borderRight: '7px solid #1A222B' }}
      date={<div style={{ fontSize: "1.2rem", }}>{experience.startDateString} - {experience.endDateString} · {experience.duration} </div>}
      iconStyle={iconStyle}
    > 
      {experience.logo && <div className={width > 1100 ? "logo-container" : ""}>
        <img src={experience.logo} className="timeline-icon" alt="Company Logo"/>
      </div>}

      <h3 className="vertical-timeline-element-title">{experience.title}</h3>
      <h4 className="vertical-timeline-element-subtitle">{experience.company}</h4>
      <h5 className="vertical-timeline-element-location">{experience.location}</h5>
      <div style={{ textAlign: "left" }}>
        {renderDescription(experience.description)}
      </div>
    </VerticalTimelineElement>
  );
};

export default JobExperience;
